.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.main {
  margin-left: 5rem;
}

@media (max-width: 900px) {
  .main {
    margin-left: auto!important;
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.title {
  border-bottom: 0.2rem solid #9B59B6;
}

/* NavBar */

li img {
  width: 2rem;
  height: 2rem;
  display: block;
  margin: auto;
}

.navbar {
  width: 5rem;
  height: 100vh;
  position: fixed;
}

.navbar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.navbar-item {
  width: 100%;
}

.nav-item:last-child {
  margin-top: auto;
}

.nav-item {
  height: 5rem;
  width: 100%;
}

.nav-item:hover {
  background: violet;
  -moz-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

.active>.nav-item {
  z-index: 999;
  background: violet;
  -moz-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

.link-text {
  text-decoration: none;
  color: white;
}

.nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  flex-direction: column;
  text-decoration: none;
}

.scroll-to-top img {
  width: 2.7rem;
  height: auto;
}

@media (max-width: 900px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
    z-index: 9999;
    background-color: #121417;
    padding-bottom: 3px;
  }
  .navbar-nav {
    flex-direction: row;
    height: 100%;
  }
  .nav-link {
    justify-content: center;
  }
  .main {
    margin-left: 5rem;
  }
  .scroll-to-top img {
    width: 3rem;
    height: auto;
  }
}

.scroll-to-top {
  animation: scrollTop 0.7s alternate ease infinite;
}

.scroll-to-top img {
  width: 2.7rem;
  height: auto;
}

@keyframes scrollTop {
  from {
    transform: translateY(2px);
  }
  to {
    transform: translateY(-5px);
  }
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 12px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #9B59B6;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #a065b8;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: white;
  background: #121417;
  overflow-x: hidden;
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 43vh;
  padding: 70px 20vw;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

section .contact {
  min-height: 47vh;
}

/* Page Marks */

.custom-shape-divider-top-1632964461 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1632964461 svg {
  position: relative;
  display: block;
  width: calc(150% + 1.3px);
  height: 100px;
}

.custom-shape-divider-top-1632964461 .shape-fill {
  fill: #9B59B6;
}

.custom-shape-divider-bottom-1632964777 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1632964777 svg {
  position: relative;
  display: block;
  width: calc(150% + 1.3px);
  height: 100px;
}

.custom-shape-divider-bottom-1632964777 .shape-fill {
  fill: #9B59B6;
}

/* Header Section */

.header {
  background: #121417;
}

.header h1 {
  font-size: 4vmin;
}

.iconsWrapper {
  position: relative;
  display: flex;
}

.iconsWrapper img {
  margin: 0 .05vmin 0 0.5vmin;
  max-width: 7vmin;
  max-height: 7vmin;
}

.iconsWrapper img:hover {
  transform: scale(1.1);
}

.photoWrap {
  position: relative;
  max-width: 13vw;
}

.photoWrap img {
  height: 100%;
  width: 100%;
  border: 4px solid #9B59B6;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

/* About section */

/* .about {
  background: #9B59B6;
} */

.about p {
  font-size: 1.6rem;
}

.about p {
  font-size: 1.6rem;
}

/* Projects Section */

.projects {
  background: #121417;
}

.image-container {
  position: relative;
}

.image-container .projectImg {
  width: 30vw;
}

.image-caption {
  width: 100%;
  height: 100%;
  background: rgb(18, 20, 23, 0.5);
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
}

.image-caption img {
  width: 3rem;
  height: auto;
}

.image-caption a {
  background: #9B59B6;
  border-radius: 10%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.imgExt {
  opacity: 0;
  width: 1.5rem;
  background: #9B59B6;
  padding: 0.25rem;
  border-radius: 10%;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
}

.image-container:hover .imgExt {
  opacity: 1;
}

.image-container:hover .image-caption {
  opacity: 1;
}

.projectImgWrapper {
  display: flex;
}

.bottomBtn {
  position: absolute;
  bottom: 1.5rem;
  position: absolute;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.projectImgWrapper img {
  margin: 0.3vw;
}

@media (max-width: 900px) {
  .wrapProjectGif .image-container img {
    width: 50vw;
  }
  .wrapProjectGif .image-caption img {
    width: 3rem
  }
}

/* Technologies Section */

/* .technologies {
  background: #9B59B6;
} */

.gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.6vw;
}

/* .gallery img:hover {
  transform: scale(1.02);
} */

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 900px) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.6vw;
  }
}

/* Interests Section */

.interests {
  background: #121417;
}

/* Cards */

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.6vw;
}

.flip-card {
  background-color: transparent;
  width: 275px;
  height: 275px;
  border: 1px solid #f1f1f1;
  /* perspective: 1000px; */
}

.flip-card img {
  background-color: transparent;
  width: 275px;
  height: 275px;
  border: 1px solid #f1f1f1;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: black;
  color: black;
}

.flip-card-back {
  background-color: #a065b8;
  color: white;
  transform: rotateY(180deg);
}

@media (max-width: 900px) {
  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(1, 70%);
    grid-gap: 0.6vw;
  }
  .flip-card {
    width: 325px;
    height: 325px;
  }
  .flip-card img {
    width: 325px;
    height: 325px;
  }
}

/* Contact Section */

.contactWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 00.6vw;
  position: relative;
  height: 100%;
}

.contactItem {
  background: #9B59B6;
  padding: 1vw;
  width: 250px;
  height: 250px;
  position: relative;
}

.contactItem:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.centered-div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.contactItem:hover {
  background: #a065b8;
  padding: 1vw;
}

ul a {
  width: 100%;
}

.last-phrase h1 {
  font-style: italic;
  font-size: 2rem;
}

.last-phrase h2 {
  float: right;
  font-style: italic;
}

.last-phrase {
  position: absolute;
  bottom: 6vh;
}

@media (max-width: 950px) {
  .last-phrase {
    position: relative;
    bottom: auto;
  }
}

#contact {
  min-height: 78vh;
}

a {
  text-decoration: none;
  color: white;
}

.contactItem img {
  max-width: 7vmin;
  max-height: 7vmin;
}

@media (max-width: 950px) {
  .contactWrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 00.6vw;
  }
  .contactItem {
    background: #9B59B6;
    padding: 1vw;
    width: 350px;
    height: 350px;
  }
  .contactItem img {
    max-width: 14vmin;
    max-height: 14vmin;
  }
}

.custom-shape-divider-bottom-1632964970 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1632964970 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 225px;
}

.custom-shape-divider-bottom-1632964970 .shape-fill {
  fill: #9B59B6;
}

/* Animations */

.stackText {
  color: #9B59B6;
}

.typing-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-cursor {
  display: inline-block;
  width: 2px;
  height: 1.7rem;
  background-color: white;
  margin-left: 8px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.input-cursor {
  animation: blink .6s linear infinite alternate;
}